import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { uiInfoService } from "../../../services";

const tableConfig = {
  initial_table: [
    { key: "mtef", label: "MTEF (12)" },
    { key: "proposed", label: "Proposed Budget (13)" },
    { key: "approved", label: "Approved Budget (21)" },
    { key: "citizen", label: "Citizens' Budget (15)" },
    { key: "quarterly", label: "Quarterly BIR (16)" },
    { key: "procurement", label: "e-Procurement Portal (11)" },
    { key: "website", label: "State Website with Fiscal Data Repository (12)" },
  ],
  second_table: [
    { key: "mtef", label: "MTEF (12)" },
    { key: "approved", label: "Approved Budget (21)" },
    { key: "citizen", label: "Citizens' Budget (15)" },
    { key: "quarterly", label: "Quarterly BIR (16)" },
    { key: "audit", label: "Audit Report (18)" },
    { key: "ag_report", label: "Accountant General’s Report (15)" },
    { key: "procurement", label: "e-Procurement Portal (11)" },
    { key: "website", label: "State Website with Fiscal Data (12)" },
  ],
  third_table: [
    { key: "mtef", label: "MTEF (12)" },
    { key: "approved", label: "Approved Budget (21)" },
    { key: "quarterly", label: "Quarterly BIR (16)" },
    { key: "audit", label: "Audit Report (18)" },
    { key: "ag_report", label: "Accountant General’s Report (15)" },
    { key: "procurement", label: "e-Procurement Portal (11)" },
    { key: "website", label: "State Website with Fiscal Data (12)" },
  ],
  default: [
    { key: "mtef", label: "MTEF (12)" },
    { key: "proposed", label: "Proposed Budget (13)" },
    { key: "approved", label: "Approved Budget (21)" },
    { key: "citizen", label: "Citizens' Budget (15)" },
    { key: "quarterly", label: "Quarterly BIR (16)" },
    { key: "audit", label: "Audit Report (18)" },
    { key: "ag_report", label: "Accountant General’s Report (15)" },
    { key: "procurement", label: "e-Procurement Portal (11)" },
    { key: "website", label: "State Website with Fiscal Data Repository (12)" },
  ],
};

const TheLeague = ({ quarters }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(
    quarters.length > 0 ? quarters[0].period_id : null
  );
  const [sortedStates, setSortedStates] = useState([]);
  const [isCustomTable, setIsCustomTable] = useState("default");

  const fetchLeagueData = useCallback(
    async (period_id) => {
      try {
        if (period_id) {
          const data = await uiInfoService.getPeriodByID(period_id);
          refreshSortedStates(data.period_id);
          if ([1, 2, 5, 9, 13, 17].includes(period_id)) {
            setIsCustomTable("initial_table");
          } else if ([6, 8, 10, 12, 14, 16].includes(period_id)) {
            setIsCustomTable("second_table");
          } else if ([7, 11, 15].includes(period_id)) {
            setIsCustomTable("third_table");
          } else {
            setIsCustomTable("default");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [uiInfoService, sortedStates]
  );

  useEffect(() => {
    fetchLeagueData(selectedPeriod);
  }, [selectedPeriod, fetchLeagueData]);

  const handleChangePeriod = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 24,
      fontWeight: 700,
    },
  }));

  const sumLastColumn = (state) => {
    const columns = tableConfig[isCustomTable];
    let divisor;

    switch (isCustomTable) {
      case "initial_table":
        divisor = 100;
        break;
      case "second_table":
        divisor = 120;
        break;
      case "third_table":
        divisor = 105;
        break;
      default:
        divisor = 133;
        break;
    }

    return (
      (columns.reduce(
        (sum, column) =>
          sum +
          (typeof state.data[column.key] === "number"
            ? state.data[column.key]
            : 0),
        0
      ) /
        divisor) *
      100
    ).toFixed();
  };

  const refreshSortedStates = (data) => {
    if (data && data.states) {
      const newSortedStates = data.states
        .slice()
        .sort((a, b) => sumLastColumn(b) - sumLastColumn(a));
      setSortedStates(newSortedStates);
    } else {
      setSortedStates([]);
    }
  };

  const getRowTextColor = (index) => {
    if (index < 9) return "#16FF00";
    if (index >= sortedStates.length - 3) return "#fc2d2d";
    return "#FDFF00";
  };

  const columns = tableConfig[isCustomTable] || tableConfig.default;

  return (
    <Box marginTop={{ xs: "15%", sm: "2%" }}>
      <Typography
        variant="h3"
        color={"#049593"}
        align={"left"}
        fontWeight={700}
        gutterBottom
        marginBottom={{ xs: "15%", sm: "2%" }}
      >
        States Fiscal Transparency League Table
      </Typography>
      <Box marginBottom={{ xs: "10%", sm: "2%" }}>
        <Typography
          variant={"subtitle2"}
          sx={{ marginBottom: 1 }}
          fontWeight={700}
          color={"#1C4E63"}
        >
          Select Quarter
        </Typography>
        <Box
          width={{ xs: "50%", sm: "25%" }}
          marginLeft={{ xs: "0%", sm: "0%" }}
        >
          <FormControl fullWidth>
            <Select
              value={selectedPeriod}
              onChange={handleChangePeriod}
              fullWidth
            >
              {quarters.map((periodId) => (
                <MenuItem key={periodId.period_id} value={periodId.period_id}>
                  {periodId.quarter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">S/N</StyledTableCell>
              <StyledTableCell align="left">Name of States</StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell key={column.key} align="center">
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">Score (%)</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedStates.length === 0 ? (
              <TableRow>
                <StyledTableCell align="left" colSpan={columns.length + 2}>
                  <Typography
                    variant="h4"
                    color={"#049593"}
                    align="center"
                    fontWeight={700}
                  >
                    No League Score
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ) : (
              sortedStates.map((state, index) => (
                <TableRow key={index} style={{ backgroundColor: "#1C4E63" }}>
                  <TableCell
                    style={{
                      color: getRowTextColor(index),
                      fontWeight: 600,
                      fontSize: 14,
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: getRowTextColor(index),
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    {state.state_name}
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.key}
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data[column.key]}
                    </TableCell>
                  ))}
                  <TableCell
                    style={{
                      color: getRowTextColor(index),
                      fontWeight: 600,
                      fontSize: 14,
                      textAlign: "center",
                    }}
                  >
                    {sumLastColumn(state)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TheLeague;
